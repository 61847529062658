import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import useAuth from "../hooks/useAuth";
import { isEmpty } from "../lib/isEmpty";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { useSelector } from "react-redux";

import Layout from "../components/Layout/Layout";
import Spinner from "../components/Spinner";
import Map from "../components/Map/MapCard";
import WeatherCard from "../components/Map/WeatherCard";

export default function Start() {
  const { user } = useAuth();
  const history = useHistory();
  const grower = useSelector((state) => state.grower);
  const location = grower.location ? grower.location : user.location;
  const forceLogin = isEmpty(grower.forceLogin)
    ? user.forceLogin
    : grower.forceLogin;

  const [coord, setCoord] = useState();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error] = useState(null);
  const apiKey = '62871f0120b4b7d8a359f2a0a0adf567';

  // eslint-disable-next-line
  useEffect(() => {
    if (forceLogin === 1) {
      history.push("/neues-passwort");
    }
    // eslint-disable-next-line
  }, [user]);

  // setup
  const provider = new OpenStreetMapProvider({
    params: {
      countrycodes: "de",
      "accept-language": "de",
    },
  });

  async function getCoordinates() {
    const results = await provider.search({
      query: location,
    });
    return results[0];
  }

  const getWeather = async (lat, lon) => {
    const api_call = await fetch(
      `//api.openweathermap.org/data/3.0/onecall?lat=${lat}&lon=${lon}&exclude=hourly,minutely&units=metric&lang=de&appid=${apiKey}`
    );
    const data = await api_call.json();
    setData(data || []); //this is the good stuff
  };

  useEffect(() => {
    setLoading(true);
    if (!coord) {
      getCoordinates()
        .then((position) => {
          setCoord({
            lat: position.y,
            lon: position.x,
          });
          getWeather(position.y, position.x);
        })
        .catch((err) => {
          // Fallback to Mainburg Location
          setCoord({
            lat: 48.6319395,
            lon: 11.7778841,
          });
          getWeather(48.6319395, 11.7778841);
        })
        .finally(() => setLoading(false));
    } else {
      getWeather(coord.lat, coord.lon);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [coord]);

  return (
    <Layout>
      {loading && <Spinner />}
      {!loading && error && <div>{`Error: ${error}`}</div>}
      {!loading && !error && data && (
        <>
          <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 m-2 mb-4">
            <div className="px-4 py-5 sm:px-6">
              <h1 className="font-display uppercase text-3xl">Startseite</h1>
            </div>
            <div className="px-4 py-5 sm:p-6 z-0">
              <Map setCoord={setCoord} coord={coord} />
            </div>
          </div>
          <div className="m-2 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {data.daily && data.daily.map((day, idx) => (
              <WeatherCard day={day} key={idx} />
            ))}
          </div>
        </>
      )}
    </Layout>
  );
}
