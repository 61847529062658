import axios from "axios";

export const addUvm = (values, history) => async (dispatch) => {
  const res = await axios.post("/api/uvm", values);
  dispatch({ type: "GET_UVM", payload: res.data });
  history.push("/admin/uvm");
};

export const updateUvm = (id, values, history) => async (dispatch) => {
  const res = await axios.patch(`/api/uvm/${id}`, values);
  dispatch({ type: "GET_UVM", payload: res.data });
  console.log(history)
  history.push("/admin/uvm");
};

export const deleteUvm = (id, history) => async (dispatch) => {
  const res = await axios.delete(`/api/uvm/${id}`);
  dispatch({ type: "GET_UVM", payload: res.data });
  history.push("/admin/uvm");
};
