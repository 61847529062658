import useSWR from 'swr';
import fetcher from "../../lib/fetcher";

import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useState} from "react";

import PriceListForm from "../../components/Forms/PriceListForm";
import AdminLayout from "../../components/Layout/AdminLayout";
import Table from "../../components/Table/Table";
import Spinner from "../../components/Spinner";
import Modal from "../../components/Modal";
import Error from "../../components/Error";

import {PencilIcon, TrashIcon} from "@heroicons/react/outline";
import {deletePriceList} from "../../actions/priceListActions";
import TableCellDate from "../../components/Table/TableCellDate";
import TableCellFormat from "../../components/Table/TableCellFormat"
import useGrower from "../../hooks/useGrower";

export default function PriceLists(props) {
  const { data, error, mutate } = useSWR("/api/price-list", fetcher,);
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);

  const grower = useGrower();

  const deleteClick = async(id) => {
    dispatch(deletePriceList(id, props.history));
    mutate();
  };


  const columns = [
    { Header: "Sorte", accessor: "hop.Beschreibung" },
    { Header: "Vertragsart", accessor: "Vertragsart", Cell: ({ value }) => <TableCellFormat type="contract" value={value} /> },
    { Header: "Lieferart", accessor: "Lieferart", Cell: ({ value }) => <TableCellFormat type="delivery" value={value} /> },
    { Header: "Angebotsart", accessor: "Preisliste", Cell: ({ row }) => <TableCellFormat type="pricelist" value={row.original} /> },
    { Header: "Qualität", accessor: "Qualität", Cell: ({ value }) => <TableCellFormat type="quality" value={value} /> },
    { Header: "Alpha", accessor: "Alpha von", Cell: ({ value }) => <TableCellFormat type="alpha" value={value} /> },
    { Header: "Preis", Cell: ({ row }) => <TableCellFormat type="price" value={row.original} /> },
    { Header: "Einkäufer", accessor: "cruser" },
    { Header: "Datum", accessor: "created_at", Cell: ({ value }) => <TableCellDate value={value} /> },
    {
      accessor: "activate", Cell: ({ row }) => {
        const label = row.original['Variante gültig'] ? "Aktiv" : "Inaktiv";
        return <span className={row.original['Variante gültig'] ? 'text-green-500' : "text-red-500"}>{label}</span>;
      }
    },
    {
      accessor: "id",
      Cell: (props) => (
        <Link to={`/admin/preislisten/${props.value}`}>
          <PencilIcon
            className="block h-6 w-6 hover:text-primary"
            aria-hidden="true"
          />
        </Link>
      ),
    },
    {
      accessor: "delete",
      Cell: (props) => (
        <Modal
          icon={<TrashIcon className="block h-6 w-6 hover:text-primary" aria-hidden="true" />}
          modalAction={() =>
            deleteClick(props.cell.row.original.id)
          }
          modalText="Preisliste wirklich löschen?"
          modalButton="Löschen"
        />
      ),
    },
  ];

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;
  return (
    <AdminLayout>
      {edit ? (
        <PriceListForm {...props} defaultValues={""} edit={edit} setEdit={setEdit} grower={grower} mutate={mutate} />
      ) : (
        <Table
          button={() => setEdit(true)}
          buttonLabel="Neue Preisliste"
          search={true}
          title="Preislisten"
          data={data.data}
          columns={columns}
        />
      )}
    </AdminLayout>
  );
}
