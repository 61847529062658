import {useFormContext} from "react-hook-form-new";

export default function Input({ label, name, type, placeholder }) {
  const { register } = useFormContext(); // retrieve all hook methods

  return (
    <div className="mt-2">
      <label htmlFor={name} className={label ? "block text-sm font-medium text-gray-700" : "sr-only"}>
        {label ? label : name}
      </label>
      <input
        {...register(name)}
        type={type ? type : "text"}
        className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
        placeholder={placeholder}
      />
    </div>
  );
}
